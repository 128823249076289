import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel'
import {  Grid,  Typography, Link } from '@material-ui/core/';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import theme from '../theme';


const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    // padding: theme.spacing(0, 2, 0, 2),
    margin: theme.spacing(10, 0, 10, 0),
  },
  img: {
    width: 350,
    height: 150,
      '@media (min-width:1200px)': {
        width: 700,
        height: 300,
      },
  }
}));

export default function Techcarousel() {
  const classes = useStyles();

const images = [
    {
      id: '1',
      url: 'https://paloaltonetworks.com',
      title: 'Palo Alto Networks',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/paloalto-w700-h300.png"
    },
    {
        id: '2',
        url: 'https://azure.com',
        title: 'Microsoft Azure',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/azure-w700-h300.png"
      },
    {
      id: '3',
      url: 'https://www.python.org',
      title: 'Python',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/python-w700-h300.png"
    },
    {
        id: '4',
      url: 'https://www.terraform.io',
      title: 'Terraform',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/terraform-w700-h300.png"
    },
    {
        id: '5',
      url: 'https://aws.amazon.com',
      title: 'AWS',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/aws-w700-h300.png"
    },
    {
        id: '6',
      url: 'https://www.cisco.com',
      title: 'Cisco',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/cisco-w700-h300.png"
    },
    {
      id: '7',
      url: 'https://github.com',
      title: 'GitHub',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/github-w700-h300.png"
    },
    {
        id: '8',
        url: 'https://microsoft.com',
        title: 'Microsoft',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/microsoft-w700-h300.png"
      },
      {
        id: '9',
        url: 'https://www.ansible.com',
        title: 'Ansible',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/ansible-w700-h300.png"
      },
      {
        id: '10',
        url: 'https://www.ui.com',
        title: 'Ubiquiti Networks',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/ubiquiti-w700-h300.png"
      },
      {
        id: '11',
        url: 'https://www.f5.com',
        title: 'F5/NGINX Load Balancers',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/f5-w700-h300.png"
      },
      {
        id: '12',
        url: 'https://workspace.google.com',
        title: 'G-Suite',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/gsuite-w700-h300.png"
      },
      {
        id: '13',
        url: 'https://www.office.com/',
        title: '0365',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/0365-w700-h300.png"
      },
      {
        id: '14',
        url: 'https://www.vmware.com',
        title: 'vmware',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/vmware-w700-h300.png"
      },
      {
        id: '15',
        url: 'https://nodejs.dev',
        title: 'Node JS',
        width: '40%',
        img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/nodejs-w700-h300.png"
      },
  ];

  return (
    <div className={classes.root}>
        <Carousel elevation={0}  
            NextIcon={<ArrowForwardIosIcon/>}
            PrevIcon={<ArrowBackIosIcon/>}
            navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    color: theme.palette.secondary.contrastText
                }
            }} 
            >
        
            {
                images.map( (images, i) => <Item key={i} images={images} /> )
            }
        </Carousel>
  </div>
  );
}

function Item(props)
{
    const classes = useStyles();

    return (
        <Grid container spacing={10} alignContent='center' align='center'>
            <Grid item xs={12} sm={12} md={12}>
            <img src={props.images.img} className={classes.img} alt={props.images.title} />
            <Link href={props.images.url}>
            <Typography variant='subtitle2'>
            {props.images.title}
            </Typography>
            </Link>
            </Grid>
        </Grid>
    )
}