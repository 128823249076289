import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Techcarousel from './Techcarousel';
import TECHDATA from '../data/technologies.json'


const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    //padding: theme.spacing(0, 0, 0, 0),
    // margin: theme.spacing(0, 10, 0, 0),
  },
  grid: {
    padding: theme.spacing(8)
  },
}));

export default function Technologies() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      { TECHDATA.map(item => {
            return(
              <div key={item.id}>
                <Grid container alignContent='center' align='center'>
                <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h2">
                {item.title}
                  </Typography>
                </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.grid}>
              <Typography variant="body1">
              {item.content}
              </Typography>
              </Grid>
              </Grid>
              </div>
            )
          })
          }
         <Techcarousel></Techcarousel>
  </div>
  );
}
