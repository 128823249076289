import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography, Grid } from '@material-ui/core';
import Header2 from '../components/Header2'
import {useSpring, animated} from 'react-spring'



const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '50vh',
    minHeight: 300,
    maxHeight: 1000,
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
      minHeight: 400,
      maxHeight: 1000,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  },
  arrowDown: {
    position: 'absolute',
    bottom: theme.spacing(4),
  },
  bannertitle:{
      backgroundColor: '#00000070',
      color: '#ffffff',
      height: '100px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '100px',
      },
  }
});

function Bannerimg(props) {
  const { backgroundClassName, children, classes } = props;

  const props2 = useSpring({
    opacity: 1, 
    from: {opacity: 0},
    config: {
      delay: 1000,
      duration: 2000,
    }
  });

  return (
    <section className={classes.root}>
      
      <Container className={classes.container}>
        <animated.div style={props2}>
        <Header2></Header2>
        </animated.div>

        {children}
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, backgroundClassName)} />
        
        <Box 
            position="absolute"
            bottom={0}
            className={classes.bannertitle}
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
            <Grid>
            <animated.div style={props2}>
            <Typography variant='body1' align='center'>
                DEVELOPING INNOVATIVE STRATEGIES
            </Typography>
            <Typography variant='h3' align='center'>
                ACHIEVING GROWTH
            </Typography>
            </animated.div>
            </Grid>
        </Box>
    
      </Container>
    
    </section>
  );
}

Bannerimg.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Bannerimg);