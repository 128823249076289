import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Paper, Grid, Button, Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    //padding: 16,
    margin: 'auto',
    maxWidth: 600,
  },

}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Emailform() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    setOpen(true);
  
    axios.get('/api/ContactUsEmail/',{
      params: {
        name: (values.firstName),
        lastname: (values.lastName),
        email: (values.email),
        subject: (values.subject),
        message: (values.message),
        key: "MRITB2f0IC0J0yhY2WADYR8CFc3v",
      }
    })
    .then(res => {
      console.log(res)
    })
    setOpen(true)
  };
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    return errors;
  };
  
  console.log(process.env.REACT_APP_API_KEY)

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ }}
        validate={validate}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16 }} elevation={0}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="firstName"
                    component={TextField}
                    type="text"
                    label="First Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="lastName"
                    component={TextField}
                    type="text"
                    label="Last Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    fullWidth
                    required
                    component={TextField}
                    type="email"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    name="subject"
                    component={TextField}
                    type="text"
                    label="subject"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="message"
                    component={TextField}
                    multiline
                    label="Message"
                  />
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    size="large"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          We will get back to you shortly
        </Alert>
      </Snackbar>
    </div>
  );
}
