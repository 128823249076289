import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#2A9D8F',
      main: '#264653',
      dark: '#23404B',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E9C46A',
      main: '#F4A261',
      dark: '#E76F51',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "Open Sans, Helvetica",
    h2: {
    '@media (min-width:0px)': {
      fontSize: '2.3rem',
    },
    '@media (min-width:600px)': {
      fontSize: '2.5rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '2.9rem',
    },
    '@media (min-width:1920px)': {
      fontSize: '3.3rem',
    },
    },
    h3: {
      '@media (min-width:0px)': {
        fontSize: '1.8rem',
      },
      '@media (min-width:600px)': {
        fontSize: '2.3rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '2.7rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '2.9rem',
      },
      },
  }
});

export default theme
