import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Divider } from '@material-ui/core';
import Servicecard from './Servicecard';
import SERVICESDATA from '../data/services.json'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 2, 2),
    margin: theme.spacing(6, 6, 6, 6),
  },
  titlegrid:{
    margin: theme.spacing(0, 0, 10, 0),
  }
}));

export default function Services(props) {
  const classes = useStyles();

  return (
      <div className={classes.root} style={props}>
        <Grid container>
        <Grid item xs={12} sm={12} md={12} align="center" className={classes.titlegrid}>
            <Typography variant='h2'>
                  SERVICES
              </Typography>
          </Grid>
          <Grid 
            container
            spacing={6}
            alignItems="flex-start"
            justify="center">
              { SERVICESDATA.map(item => {
                return(
                  <Servicecard
                    title={item.title}
                    text={item.content}
                    key={item.id}
                  ></Servicecard>
                )
              })
              }
        </Grid>
        </Grid>
        <Divider></Divider>
    </div >
  );
}