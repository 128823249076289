import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Mountain Ridge IT Consulting
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    // marginTop: theme.spacing(8),
    padding: theme.spacing(2, 2, 2, 2),
    margin: theme.spacing(6, 3, 6, 3),
  },
  sizeAvatar: {
  },
  grid: {
    padding: theme.spacing(1),
  },
}));
 
export default function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  const images = [
    {
      url: 'https://github.com',
      title: 'github',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/github-d.png"
    },
    {
      url: 'https://facebook.com',
      title: 'facebook',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/facebook-d.png"
    },
    {
      url: 'https://instagram.com',
      title: 'instagram',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/instagram-d.png"
    },
    {
      url: 'https://stackoverflow.com',
      title: 'stackoverflow',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/stackoverflow-d.png"
    },
    {
      url: 'https://twitter.com',
      title: 'twitter',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/twitter-d.png"
    },
    {
      url: 'https://linkedin.com',
      title: 'linkedinn',
      width: '40%',
      img: "https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/linkedin-d.png"
    },
  ];

  return (
    <footer className={classes.footer}>
    <Grid container spacing={0}>
      { images.map((item, index) => (
         <Grid className={classes.grid} key={item.title}>
          <a  href={item.url}>
           <Avatar src={item.img}/>
         </a>
       </Grid>
    ))}
    </Grid>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {description}
        </Typography>
        <Copyright />
      </Container>
      <Typography variant="caption" align="center">
          06072021-2
      </Typography>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};