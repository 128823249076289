import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import About from './pages/About';
import Banner from './components/Banner';
import Footer from './components/Footer';
import theme from './theme'

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Banner></Banner>
        <Switch>
          <Route path='/' exact component={About} />
        </Switch>
        <Footer></Footer>
      </Router>
    </ThemeProvider>
  );
}
export default App;
