import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Aos from "aos";
import "aos/dist/aos.css";
import Aboutcomp from '../components/Aboutcomp';
import Technologies from '../components/Technologies';
import Services from '../components/Services';
import Emailform from '../components/Emailform';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));

  
export default function About() {
    const classes = useStyles();

    useEffect(() => {
      Aos.init({
        duration: 2000,
      });
    }, []);

  
  return (
    <div className={classes.root}>
    <div data-aos="fade-up">
       <Services></Services>
       </div>
    <div data-aos="fade-up">
       <Technologies></Technologies>
       </div>
    <div data-aos="fade-up">
       <Aboutcomp></Aboutcomp>
    </div>
    <div data-aos="fade-up">
    <Emailform></Emailform>
    </div>
    </div>
  );
}
