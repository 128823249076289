import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {  Grid, Card } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  box: {
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
  card: {
    maxWidth: 375,
    marginBottom: 60,
  },
}));

export default function Servicescard(props) {

  const classes = useStyles();

  return (
      <Grid item xs={12} sm={12} md={6} align="center" >
      <Card className={classes.card} elevation={0}>
          <Typography variant="h5" className={classes.title}>
          {props.title}
          </Typography>
          <br></br>
          <Typography variant="body1" className={classes.title}>
              {props.text}
          </Typography>
        </Card>
      </Grid>
  );
}