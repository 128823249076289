import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CardMedia } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  links: {
    textDecoration: 'none',
  },
  appbar: {
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(1),
    height: '80px'
  },
  media: {
    height: 100,
    width: 100,
  }
}));

const logoImage = 'https://mritwebhostingstorage1.blob.core.windows.net/mrtiweb/websitelog.png';


export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appbar}>
        <Toolbar>
        <CardMedia
          image={logoImage}
          title="Contemplative Reptile"
          className={classes.media}
        />
          <Typography variant="h3" className={classes.title}>
            Mountain Ridge IT Consulting
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}