import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider } from '@material-ui/core';
import ABOUTDATA from '../data/about.json'


const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    padding: theme.spacing(2, 2, 2, 2),
    margin: theme.spacing(6, 6, 6, 6),
  },
  grid: {
    padding: theme.spacing(4)
  },
}));

export default function AboutComp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      { ABOUTDATA.map(item => {
            return(
              <div key={item.id}>
                <Grid container spacing={10} alignContent='center' align='center' className={classes.grid}>
                <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h2">
                {item.title}
                  </Typography>
                </Grid>
              <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1">
              {item.content}
              </Typography>
              </Grid>
              </Grid>
              </div>
            )
          })
          }
         <Divider></Divider>
  </div>
  );
}
